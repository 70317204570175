import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ isActive, setIsActive, setShow, show }) {
  return (
    <aside>
      <div className="sidebar">
        <div className="logo-side">
          <Link>
            <img
              className="big"
              src={require("../../Assets/Images/side-bar-logo.svg").default}
            />
          </Link>
        </div>
        <div className="side-menu">
          <div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == ""
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to=""
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2.5"
                    y="10"
                    width="5"
                    height="6.66667"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="7.5"
                    y="6.66675"
                    width="5"
                    height="10"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="12.5"
                    y="3.33325"
                    width="5"
                    height="13.3333"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.3335 16.6667H15.0002"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> Analytics</p>
              </Link>
            </div>

            <div className="side-pages">
              <p>PAGES</p>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user" ||
                  window.location.pathname == "/profile-view"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="11.0002"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> Users</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user-verification" ||
                  window.location.pathname == "/user-verification-profile-view"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user-verification"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="8.25016"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.75 19.25V17.4167C2.75 15.3916 4.39162 13.75 6.41667 13.75H10.0833C12.1084 13.75 13.75 15.3916 13.75 17.4167V19.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6665 10.0833L16.4998 11.9167L20.1665 8.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> User Verification</p>
              </Link>
            </div>

            <div className="side-btm-space new-icon-report">
              <Link
                className={
                  window.location.pathname == "/report" ||
                  window.location.pathname == "/report-details"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/report"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.9375 0.6875C6.27963 0.6875 4.125 2.84213 4.125 5.5C4.125 8.15787 6.27963 10.3125 8.9375 10.3125C11.5954 10.3125 13.75 8.15787 13.75 5.5C13.75 2.84213 11.5954 0.6875 8.9375 0.6875ZM5.5 5.5C5.5 3.60147 7.03897 2.0625 8.9375 2.0625C10.836 2.0625 12.375 3.60147 12.375 5.5C12.375 7.39853 10.836 8.9375 8.9375 8.9375C7.03897 8.9375 5.5 7.39853 5.5 5.5ZM6.71344 11.6875C3.38491 11.6875 0.6875 14.3852 0.6875 17.7134C0.6875 19.7007 2.299 21.3125 4.28656 21.3125H13.5884C15.5757 21.3125 17.1875 19.701 17.1875 17.7134C17.1875 14.3849 14.4898 11.6875 11.1616 11.6875H6.71344ZM2.0625 17.7134C2.0625 15.1442 4.14459 13.0625 6.71344 13.0625H11.1616C13.7308 13.0625 15.8125 15.1446 15.8125 17.7134C15.8125 18.3033 15.5782 18.869 15.1611 19.2861C14.744 19.7032 14.1783 19.9375 13.5884 19.9375H4.28656C3.6967 19.9375 3.13101 19.7032 2.71391 19.2861C2.29682 18.869 2.0625 18.3033 2.0625 17.7134Z"
                    fill="#444151"
                  />
                  <path
                    d="M17.1875 8.9375C17.0052 8.9375 16.8303 8.86507 16.7014 8.73614C16.5724 8.6072 16.5 8.43234 16.5 8.25V1.375C16.5 1.19266 16.5724 1.0178 16.7014 0.888864C16.8303 0.759933 17.0052 0.6875 17.1875 0.6875C17.3698 0.6875 17.5447 0.759933 17.6736 0.888864C17.8026 1.0178 17.875 1.19266 17.875 1.375V8.25C17.875 8.43234 17.8026 8.6072 17.6736 8.73614C17.5447 8.86507 17.3698 8.9375 17.1875 8.9375ZM17.1875 11C17.0052 11 16.8303 10.9276 16.7014 10.7986C16.5724 10.6697 16.5 10.4948 16.5 10.3125C16.5 10.1302 16.5724 9.9553 16.7014 9.82636C16.8303 9.69743 17.0052 9.625 17.1875 9.625C17.3698 9.625 17.5447 9.69743 17.6736 9.82636C17.8026 9.9553 17.875 10.1302 17.875 10.3125C17.875 10.4948 17.8026 10.6697 17.6736 10.7986C17.5447 10.9276 17.3698 11 17.1875 11Z"
                    fill="#444151"
                  />
                </svg>

                <p>Report</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/chat-support" ||
                  window.location.pathname == "/chat-box"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/chat-support"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3.6665"
                    y="11.9165"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="14.6665"
                    y="11.9165"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.6665 13.7498V10.9998C3.6665 6.94975 6.94975 3.6665 10.9998 3.6665C15.0499 3.6665 18.3332 6.94975 18.3332 10.9998V13.7498"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 17.4165C16.5 18.9353 14.0376 20.1665 11 20.1665"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Support</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/notification" ||
                  window.location.pathname == "/create-notification"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/notification"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.1665 4.58333C9.1665 3.57081 9.98732 2.75 10.9998 2.75C12.0124 2.75 12.8332 3.57081 12.8332 4.58333C14.9783 5.59767 16.3886 7.71306 16.4998 10.0833V12.8333C16.6397 13.9891 17.3201 15.0097 18.3332 15.5833H3.6665C4.67953 15.0097 5.35994 13.9891 5.49984 12.8333V10.0833C5.61109 7.71306 7.02135 5.59767 9.1665 4.58333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 15.5835V16.5002C8.25 18.0189 9.48122 19.2502 11 19.2502C12.5188 19.2502 13.75 18.0189 13.75 16.5002V15.5835"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Notifications</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
