import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Error from "./Pages/Error";
import UnderMaintenance from "./Pages/UnderMaintenance";
import OtpVarification from "./Pages/OtpVarification";
import MyProfile from "./Pages/MyProfile";
import User from "./Pages/User";
import ProfileView from "./Pages/ProfileView";
import Notifications from "./Pages/Notifications";
import CreateNotification from "./Pages/CreateNotification";
import ChatSupport from "./Pages/ChatSupport";
import ChatBox from "./Pages/ChatBox";
import Report from "./Pages/Report";
import ReportDetails from "./Pages/ReportDetails";
import { ProfileProvider } from "./Context/ProfileContext";
import ProtectedRoutes from "./Components/Layout/ProtectedRoutes";
import UserVerification from "./Pages/UserVerification";
import UserVerificationProfileView from "./Pages/UserVerificationProfileView";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ProfileProvider>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route
              path="/otp-varification"
              element={<OtpVarification />}
            ></Route>
            <Route
              path="/reset-password/:id"
              element={<ResetPassword />}
            ></Route>
            <Route element={<ProtectedRoutes />}>
              <Route path="/error" element={<Error />}></Route>
              <Route
                path="/under-maintenance"
                element={<UnderMaintenance />}
              ></Route>

              <Route path="/user" element={<User />}></Route>
              <Route path="/my-profile" element={<MyProfile />}></Route>

              <Route path="/profile-view/:id" element={<ProfileView />}></Route>
              <Route path="/notification" element={<Notifications />}></Route>
              <Route
                path="/create-notification"
                element={<CreateNotification />}
              ></Route>
              <Route path="/chat-support" element={<ChatSupport />}></Route>
              <Route path="/chat-box" element={<ChatBox />}></Route>
              <Route path="/report" element={<Report />}></Route>
              <Route path="/report-details" element={<ReportDetails />}></Route>
              <Route
                path="/user-verification"
                element={<UserVerification />}
              ></Route>
              <Route
                path="/user-verification-profile-view"
                element={<UserVerificationProfileView />}
              ></Route>
            </Route>
          </Routes>
        </ProfileProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
