import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";



// user listing  

export const Userlisting = createAsyncThunk(
    "Userlisting",
    async (details) => {
      let url = `/user_listing?page=${details?.page}&limit=${details?.limit}`;
  
      if (details.search) {
        url += `&name=${details.search}`;
      }
      const data = await AdminAPI.get(url);
      console.log(data);
      return data;
    }
  );

  // dowanload user list 

  export const dowanloaduserslist = createAsyncThunk(
    "dowanloaduserslist",
    async () => {
      const data = await AdminAPI.get(`/user_listing`);
      console.log(data);
      return data;
    }
  );

  // get admindetail 
  export const getAdmindetails = createAsyncThunk(
    "getAdmindetails",
    async () => {
      const data = await AdminAPI.get(`/get_admin_detail`);
      console.log(data);
      return data;
    }
  );

  // change password 
  
  export const changepass = createAsyncThunk(
    "changepass",
    async (details) => {
      const data = await AdminAPI.post(`/change_adminpassword`,details);
      console.log(data);
      return data;
    }
  );

    // edit admin Profile  
  
    export const EditProfile = createAsyncThunk(
      "EditProfile",
      async (details) => {
        const data = await AdminAPI.post(`/update_profile`,details);
        console.log(data);
        return data;
      }
    );



    // =============== Activate or deactivate user =================

export const Suspenduser = createAsyncThunk(
  "Suspenduser",
  async (details) => {
    const data = await AdminAPI.post(`/suspend_account`, details);
    console.log(data);
    return data;
  }
);

  // ======== Delete User account api =============

  export const DeleteUser = createAsyncThunk(
    "DeleteUser",
    async(details) => {
        const data = await AdminAPI.delete(`/deleteAccount/${details?.id}`);
       return data;
    } 
  );


  // ===== Get user Profile =====

export const getuserprofile = createAsyncThunk(
  "getuserprofile",
  async (details) => {
    const data = await AdminAPI.get(`/get_user_detail?id=${details?.userId}`);
    console.log(data);
    return data;
  }
);


export const Resendotp = createAsyncThunk(
  "Resendotp",
  async (details) => {
    const data = await AdminAPI.post(`/resend_otp`,details);
    console.log(data);
    return data;
  }
);

  
