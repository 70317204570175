import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../Redux/Actions/AdminAuth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(<span className="error-message">Invalid Email Address</span>)
    .required(<span className="error-message">Required</span>),
  password: Yup.string()
    .min(6, <span className="error-message">Password too short</span>)
    .required(<span className="error-message">Required</span>),
});

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = (values) => {
    dispatch(login(values))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          toast.success(`${res?.payload?.message} ${res?.payload?.data?.otp}`);
          localStorage.setItem("id",res?.payload?.data?._id)
          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/otp-varification";
          }, 3000);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={8} className="p-0">
            <div className="left-banner-img"></div>
          </Col>
          <Col lg={4}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2>Welcome to BeeMine! 👋🏻</h2>
                  <p>Please sign-in to your account and start the adventure</p>
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={LoginSchema}
                    onSubmit={handleLogin}
                  >
                    {({ values, handleChange, handleSubmit }) => (
                      <Form>
                        <div className="form-set">
                          <Form.Group
                            className="mb-4"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter your email "
                              name="email"
                              value={values?.email}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="email" component="div" />
                          </Form.Group>

                          <Form.Group
                            className="mb-3 pass-eys"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              name="password"
                              value={values?.password}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="password" component="div" />
                            {showPassword ? (
                              <img
                                src={
                                  require("../Assets/Images/eye-fill.svg")
                                    .default
                                }
                                onClick={togglePasswordVisibility}
                              />
                            ) : (
                              <img
                                src={
                                  require("../Assets/Images/hidepassword1.svg")
                                    .default
                                }
                                onClick={togglePasswordVisibility}
                              />
                            )}
                          </Form.Group>
                        </div>
                        <div className="pass-rember-line">
                          {/* <div className="remember-check">
                        <input type="checkbox" class="red" id="filled-in-box" />
                        <Form.Label>Remember Me</Form.Label>
                      </div> */}
                          <Link to="/forgot-password" className="forgot">
                            Forgot Password?
                          </Link>
                        </div>
                        <button
                          // to="/otp-varification"
                          variant="primary"
                          type="submit"
                          className="submit"
                          onClick={handleSubmit}
                        >
                          Login
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
