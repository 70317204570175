import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteUser,
  Suspenduser,
  Userlisting,
  dowanloaduserslist,
} from "../Redux/Actions/AdminAction";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import PaginationComponet from "../Components/Layout/Pagination";
import placeholder from "../Assets/Images/admin_Placeholder.png";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

export default function User() {
  const userdata = useSelector((state) => state.users?.alldata);
  console.log(userdata, "userdata");
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [activePopoverId, setActivePopoverId] = useState(null);
  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [userid, setUserid] = useState("");
  const [curruntImage, setCurruntImage] = useState("");
  const [admindetails, setAdminDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      Userlisting({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };
    dispatch(Userlisting(searchData));
  }, [page, limit, search, flag]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(dowanloaduserslist());
      console.log(response);

      const allData = response?.payload?.data?.data?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const ActiveOrDeactivate = (id, status) => {
    dispatch(Suspenduser({ id: id, is_suspend: status }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === "200") {
          toast.success(res?.payload?.data?.message);
          setActivePopoverId(null);
          setflag(!flag);
        }
      })
      .catch((error) => {
        console.log(error, "Error occurred");
      });
  };

  const handleTogglePopover = (id) => {
    setActivePopoverId((prevId) => (prevId === id ? null : id));
  };

  const popover = (id) => {
    return (
      <Popover id="popover-basic" className="custom-popover">
        <Popover.Body>
          <p
            className="common-cursor-pointer"
            onClick={() => ActiveOrDeactivate(id, 0)}
          >
            Active
          </p>
          <p
            className="common-cursor-pointer"
            onClick={() => ActiveOrDeactivate(id, 1)}
          >
            Suspend
          </p>
        </Popover.Body>
      </Popover>
    );
  };
  

   const handlenavigate = (id) => {
    navigate(`/profile-view/${id}`)
   }

  const handleDeleteUSer = () => {
    dispatch(DeleteUser({ id: userid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                <Row>
                <Col xxl={4} xl={4} lg={4}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Total Users</p>
                          <h2>00</h2>
                          {/* <span className="total-users">Total Users</span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/session-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={4}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Verified Users </p>
                          <h2>00</h2>
                          
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/padi-user.svg").default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={4}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Active Users</p>
                          <h2>00</h2>
                          {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/active-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Pending Service providers</p>
                          <h2>00</h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/pending-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search User"
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>
                      <div className="user-checkbox">User</div>
                    </th>
                    <th>Role</th>
                    <th>Contact</th>
                    <th>Email </th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userdata?.data?.users?.map((res, index) => {
                    const dynamicIndex = (page - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>{dynamicIndex}</td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                              <img
                                src={
                                  res?.selfie_image
                                    ? `${url}public/uploads/${res?.selfie_image}`
                                    : placeholder
                                }
                                style={{ borderRadius: 50 }}
                              />
                              <div className="user-id">
                                <p>{res?.name || "N/A"}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>
                              <span>
                                <img
                                  src={
                                    require("../Assets/Images/table-user.svg")
                                      .default
                                  }
                                />
                              </span>
                              user
                            </p>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="cont-num">
                            {" "}
                            {res?.phone_number
                              ? res.country_code
                                ? `${res.country_code} ${res.phone_number}`
                                : `${res.phone_number}`
                              : "N/A"}
                          </div>
                        </td>
                        <td>
                          <div className="debit">{res?.email || "N/A"}</div>
                        </td>
                        <td>
                          <div
                            className={
                              res?.is_suspend === 1 ? "pending" : "active"
                            }
                          >
                            <p>
                              {res?.is_suspend === 1 ? "Suspended" : "Active"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/delete-icon.svg")
                                  .default
                              }
                              onClick={() => {
                                setshowd(true);
                                setUserid(res?._id);
                              }}
                            />
                            <img
                              src={
                                require("../Assets/Images/view-icon.svg")
                                  .default
                              }
                              onClick={() => handlenavigate(res?._id)}
                            />
                            {/* <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            /> */}
                            <OverlayTrigger
                              trigger="click"
                              show={activePopoverId === res._id}
                              placement="bottom"
                              overlay={popover(res?._id)}
                              onToggle={() => handleTogglePopover(res._id)}
                              rootClose
                            >
                              <img
                                src={
                                  require("../Assets/Images/options.svg")
                                    .default
                                }
                                alt="Options"
                                style={{ cursor: "pointer" }}
                              />
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (currentPage - 1) * limit} -{" "}
                    {Math.min(currentPage * limit, userdata?.data?.totalUsers)}{" "}
                    of {userdata?.data?.totalUsers} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={currentPage}
                    totalCount={userdata?.data?.totalUsers}
                    limit={userdata?.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="Codobux">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to delete this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn conform-btn" onClick={handleDeleteUSer}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshowd(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

    </Layout>
  );
}
